<template>
    <div id="left-sidebar" class="sidebar">
        <div class="">
            <div class="navbar-brand">
                <router-link class="main-logo" to="/dashboard-v1" v-if="toggleSidebarTheme == ''"><img src="@/assets/payangel_logo.png" alt="PayAngel Logo" class="img-responsive logo"></router-link>
                <router-link class="main-logo-icon" to="/dashboard-v1" v-if="toggleSidebarTheme == ''"><img src="@/assets/logo-icon.svg" alt="PayAngel Logo" class="img-responsive logo img-fluid" /></router-link>

                <router-link class="main-logo" to="/dashboard-v1" v-if="toggleSidebarTheme == 'full-dark'  && isSidebarSmall == false "><img src="@/assets/payangel_logo_w.png" alt="PayAngel Logo" class="img-responsive logo"></router-link>
                <router-link class="main-logo-icon" to="/dashboard-v1" v-if="toggleSidebarTheme == 'full-dark'  && isSidebarSmall == true "><img src="@/assets/logo-icon-w.svg" alt="PayAngel Logo" class="img-responsive logo img-fluid" /></router-link>

                <button type="button" class="btn-toggle-offcanvas btn btn-sm btn-default" v-on:click="backSideMenu()"><i class="lnr lnr-menu fa fa-chevron-circle-left"></i></button>
            </div>
            <div class="user-account">
                <div class="user_div">
                    <img :src="require('@/assets/user.png')" class="rounded-circle user-photo"
                        alt="User Profile Picture">
                </div>
                <div class="dropdown" v-bind:class="{ 'show': dropActive }" @click="dropActive = !dropActive"
                    v-click-outside="outside">
                    <a href="javascript:void(0);" class="dropdown-toggle user-name" data-toggle="dropdown"
                        :aria-expanded="dropActive ? 'true' : 'false'"><strong>Alizee Thomas</strong></a>
                    <ul class="dropdown-menu dropdown-menu-right account" v-bind:class="{ 'show': dropActive }"
                        :style="dropActive ? 'top: 100%; position: absolute; transform: translate3d(-52px, 42px, 0px); left: 0px; will-change: transform;' : 'top: 80%; position: absolute; transform: translate3d(-52px, 42px, 0px); left: 0px; will-change: transform;'">
                        <!-- <li><router-link to="/setting/profile"><i class="icon-user"></i>My Profile</router-link></li> -->
                        <!-- <li><router-link to="/app/app-inbox"><i class="icon-envelope-open"></i>Messages</router-link></li>
                    <li><a href="javascript:void(0);"><i class="icon-settings"></i>Settings</a></li>
                    <li class="divider"></li> -->
                        <li>
                            <router-link to="/page-login"><i class="icon-power"></i>Logout</router-link>
                        </li>
                    </ul>
                </div>
                <span>Admin</span>
            </div>
            <!-- Nav tabs -->
            <ul class="nav nav-tabs">
                <li class="nav-item"><a class="nav-link" v-on:click="setActiveNavtab('Menu')"
                        :class="{ 'active show': openNavtab('Menu') }" data-toggle="tab">Menu</a></li>
                <li class="nav-item"><a class="nav-link" v-on:click="setActiveNavtab('Setting')"
                        :class="{ 'active show': openNavtab('Setting') }" data-toggle="tab"><i
                            class="icon-settings"></i></a></li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content p-l-0 p-r-0">
                <div class="tab-pane" :class="{ 'active show': openNavtab('Menu') }" id="menu">
                    <nav id="left-sidebar-nav" class="sidebar-nav">
                        <ul id="main-menu" class="metismenu">
                            <li v-bind:class="{ 'active': currentActiveMenu == 'dashboard-v1' }">
                                <router-link to="/dashboard-v1" title="Dashboard"><i class="icon-home"></i><span>Dashboard</span></router-link>
                            </li>
                            <li :class="{ 'active': currentActiveMenu === 'country' || currentActiveMenu === 'list'}" @mouseover="isHover('list', true)" @mouseleave="isHover('list', false)">
                                <a class="has-arrow" v-on:click="setActive('country')"><i class="fa fa-list"></i> <span>Listing</span></a>
                                <ul v-if="!isListing && !isSidebarSmall" v-bind:class="currentActiveMenu == 'country' || currentActiveMenu == 'list' ? 'collapse in' : 'collapse'">
                                    <li v-bind:class="{ 'active':currentRoutePath == '/country/list' || currentRoutePath == '/country/details'}"><router-link to="/country/list">Country List</router-link></li>
                                    <!-- <li v-bind:class="{ 'active':currentRoutePath == '/list/payout-option' }"><router-link to="/list/payout-option">Payout Option</router-link></li> -->
                                    <!-- <li v-bind:class="{ 'active':currentRoutePath == '/list/payment-option' }"><router-link to="/list/payment-option">Payment Option</router-link></li> -->
                                    <!-- <li v-bind:class="{ 'active':currentRoutePath == '/list/service-type' }"><router-link to="/list/service-type">Service Type</router-link></li> -->
                                    <li v-bind:class="{ 'active':currentRoutePath == '/list/bank' }"><router-link to="/list/bank">Bank List</router-link></li>
                                    <li v-bind:class="{ 'active':currentRoutePath == '/list/benificiary-relationship' }"><router-link to="/list/benificiary-relationship">Benificiary Relationship</router-link></li>
                                    <!-- <li v-bind:class="{ 'active':currentRoutePath == '/list/business-relationship' }"><router-link to="/list/business-relationship">Business Relationship</router-link></li> -->
                                </ul>
                                <template v-else>
                                    <ul :class="isListing && isSidebarSmall ? 'collapse in' : 'collapse'">
                                        <li v-bind:class="{ 'active':currentRoutePath == '/country/list' || currentRoutePath == '/country/details'}"><router-link to="/country/list">Country List</router-link></li>
                                        <li v-bind:class="{ 'active':currentRoutePath == '/list/bank' }"><router-link to="/list/bank">Bank List</router-link></li>
                                        <li v-bind:class="{ 'active':currentRoutePath == '/list/benificiary-relationship' }"><router-link to="/list/benificiary-relationship">Benificiary Relationship</router-link></li>
                                    </ul>
                                </template>
                            </li>
                            <!-- <li :class="{ 'active': currentActiveMenu === 'customer' || currentActiveMenu === 'beneficiary'}">
                                <a class="has-arrow" v-on:click="setActive('customer')"><i class="fa fa-users"></i> <span>User List</span></a>
                                <ul v-bind:class="currentActiveMenu == 'customer' || currentActiveMenu == 'beneficiary' ? 'collapse in' : 'collapse'">
                                    <li v-bind:class="{ 'active':currentRoutePath == '/customer/list'}"><router-link to="/customer/list">Customer</router-link></li>
                                    <li v-bind:class="{ 'active':currentRoutePath == '/beneficiary/all'}"><router-link to="/beneficiary/all">Beneficiary</router-link></li>
                                </ul>
                            </li> -->
                            <li :class="{ 'active': currentActiveMenu === 'transaction'}" @mouseover="isHover('transaction', true)" @mouseleave="isHover('transaction', false)">
                                <a class="has-arrow" v-on:click="setActive('transaction')"><i class="fa fa-bars"></i> <span>Transaction List</span></a>
                                <ul v-if="!isTransactionlist && !isSidebarSmall" v-bind:class="currentActiveMenu == 'transaction' ? 'collapse in' : 'collapse'">
                                    <li v-bind:class="{ 'active':currentRoutePath == '/transaction/middleware'}"><router-link to="/transaction/middleware">Middleware</router-link></li>
                                    <!-- <li v-bind:class="{ 'active':currentRoutePath == '/transaction/arch'}"><router-link to="/transaction/arch">Arch</router-link></li> -->
                                </ul>
                                <template v-else>
                                    <ul :class="isTransactionlist && isSidebarSmall ? 'collapse in' : 'collapse'">
                                        <li v-bind:class="{ 'active':currentRoutePath == '/transaction/middleware'}"><router-link to="/transaction/middleware">Middleware</router-link></li>
                                    </ul>
                                </template>
                            </li>
                            <li :class="{ 'active': currentActiveMenu === 'customer'}" @mouseover="isHover('customer', true)" @mouseleave="isHover('customer', false)">
                                <a class="has-arrow" v-on:click="setActive('customer')"><i class="fa fa-users"></i> <span>Customer</span></a>
                                <ul v-if="!isCustomerlist && !isSidebarSmall" v-bind:class="currentActiveMenu == 'customer' ? 'collapse in' : 'collapse'">
                                    <li v-bind:class="{ 'active':currentRoutePath == '/customer/list'}"><router-link to="/customer/list">List</router-link></li>
                                </ul>
                                <template v-else>
                                    <ul :class="isCustomerlist && isSidebarSmall ? 'collapse in' : 'collapse'">
                                        <li v-bind:class="{ 'active':currentRoutePath == '/customer/list'}"><router-link to="/customer/list">List</router-link></li>
                                    </ul>
                                </template>
                            </li>
                            <!-- <li v-bind:class="{ 'active': currentActiveMenu == 'partner' }">
                                <router-link to="/partner/list" title="Partner"><i
                                        class="fa fa-user"></i><span>Partner</span></router-link>
                            </li>
                            <li v-bind:class="{ 'active': currentActiveMenu == 'validate-bank-account' }">
                                <router-link to="/validate-bank-account" title="Validate Bank Account"><i class="fa fa-bank"></i><span>Validate Bank Account</span></router-link>
                            </li>
                            <li v-bind:class="{ 'active': currentActiveMenu == 'user' }">
                                <router-link to="/user/list" title="User Management"><i class="fa fa-user"></i><span>User Management</span></router-link>
                            </li>
                            <li v-bind:class="{ 'active': currentActiveMenu == 'cms' }">
                                <router-link to="/cms/list" title="CMS Management"><i class="fa fa-file"></i><span>CMS Management</span></router-link>
                            </li>  -->
                            <li :class="{ 'active': currentActiveMenu === 'setting'}"  @mouseover="isHover('setting', true)" @mouseleave="isHover('setting', false)">
                                <a class="has-arrow" v-on:click="setActive('setting')"><i class="fa fa-cog"></i> <span>Settings</span></a>
                                <ul v-if="!isHoverSetting && !isSidebarSmall" v-bind:class="currentActiveMenu == 'setting' ? 'collapse in' : 'collapse'">
                                    <li v-bind:class="{ 'active':currentRoutePath == '/setting/compliance'}"><router-link to="/setting/compliance">Compliance</router-link></li>
                                </ul>
                                <template v-else>
                                    <ul :class="isHoverSetting && isSidebarSmall ? 'collapse in' : 'collapse'">
                                        <li v-bind:class="{ 'active':currentRoutePath == '/setting/compliance'}"><router-link to="/setting/compliance">Compliance</router-link></li>
                                    </ul>
                                </template>
                            </li>
                            
                            <!-- <li v-bind:class="{ 'active': currentActiveMenu == 'partner' }">
                            <router-link to="/partner/transaction-list"><i class="fa fa-users"></i><span>Partner Transaction</span></router-link>
                        </li> -->
                            <!-- <li :class="{ 'active': currentActiveMenu === 'partner'}">
                                <a class="has-arrow" v-on:click="setActive('partner')"><i class="fa fa-list"></i> <span>Partner</span></a>
                                <ul v-bind:class="currentActiveMenu == 'partner' ? 'collapse in' : 'collapse'">
                                    <li v-bind:class="{ 'active':currentRoutePath == '/partner/transaction-list' || currentRoutePath == '/partner/transaction-detail'}"><router-link to="/partner/transaction-list">Transaction List</router-link></li>
                                    <li v-bind:class="{ 'active':currentRoutePath == '/partner/bank-deposit' }"><router-link to="/partner/bank-deposit">Bank Deposit</router-link></li>
                                    <li v-bind:class="{ 'active':currentRoutePath == '/partner/bill-company' }"><router-link to="/partner/bill-company">Bill Company</router-link></li>
                                </ul>
                            </li> -->

                            <!-- <li :class="{ 'active': currentActiveMenu === 'service'}">
                            <a class="has-arrow" v-on:click="setActive('service')"><i class="fa fa-cog"></i> <span>Services</span></a>
                            <ul v-bind:class="currentActiveMenu == 'service' ? 'collapse in' : 'collapse'">
                                <li v-bind:class="{ 'active':currentRoutePath == '/admin/service/bank-deposits'}"><router-link to="/admin/service/bank-deposits">Bank Deposits</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/admin/service/mobile-money'}"><router-link to="/admin/service/mobile-money">Mobile Money</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/admin/service/airtime'}"><router-link to="/admin/service/airtime">Airtime</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/admin/service/sms'}"><router-link to="/admin/service/sms">SMS</router-link></li>
                                <li v-bind:class="{ 'active':currentRoutePath == '/admin/service/short-code'}"><router-link to="/admin/service/short-code">USSD Short Code</router-link></li>
                            </ul>
                        </li> -->

                        </ul>
                    </nav>
                </div>
                <div class="tab-pane p-l-15 p-r-15" :class="{ 'active show': openNavtab('Setting') }" id="setting">
                    <h6>Choose Mode</h6>
                    <ul class="choose-skin list-unstyled">
                        <li data-theme="white" :class="{ 'active': data_theme === '' }" v-on:click="setactiveTheme('')">
                            <div class="white"></div>
                            <span>Light</span>
                        </li>
                        <li data-theme="black" :class="{ 'active': data_theme === 'full-dark' }"
                            v-on:click="setactiveTheme('full-dark')">
                            <div class="black"></div>
                            <span>Dark</span>
                        </li>
                    </ul>
                    <hr>
                    <h6>Choose Skin</h6>
                    <ul class="choose-skin list-unstyled">
                        <li data-theme="radical-red" v-bind:class="{ 'active': activeColor === 'radical-red' }"
                            v-on:click="changeBodycolor('radical-red')">
                            <div class="radical-red"></div>
                            <span>Radical Red</span>
                        </li>
                        <li data-theme="turquoise" v-bind:class="{ 'active': activeColor === 'turquoise' }"
                            v-on:click="changeBodycolor('turquoise')">
                            <div class="turquoise"></div>
                            <span>Turquoise</span>
                        </li>
                        <li data-theme="midnight-express" v-bind:class="{ 'active': activeColor === 'midnight-express' }"
                            v-on:click="changeBodycolor('midnight-express')">
                            <div class="midnight-express"></div>
                            <span>Midnight Express</span>
                        </li>
                        <li data-theme="morning-glory" v-bind:class="{ 'active': activeColor === 'morning-glory' }"
                            v-on:click="changeBodycolor('morning-glory')">
                            <div class="morning-glory"></div>
                            <span>Morning Glory</span>
                        </li>
                        <li data-theme="dark-cyan" v-bind:class="{ 'active': activeColor === 'dark-cyan' }"
                            v-on:click="changeBodycolor('dark-cyan')">
                            <div class="dark-cyan"></div>
                            <span>Dark Cyan</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/plugins/event-bus.js';
export default {
    name: 'SidebarComponent',
    methods: {
        changeBodycolor(color) {
            this.activeColor = color
            document.body.className = 'theme-' + color + ' ' + this.data_theme;
        },

        outside: function () {
            this.dropActive = false
        },

        setActive: function (menuItem) {
            if (this.currentActiveMenu === menuItem) {
                this.currentActiveMenu = null;
            } else {
                this.currentActiveMenu = menuItem;
            }
        },

        openNavtab: function (tab) {
            return this.currentMenuTab === tab
        },
        setActiveNavtab: function (tab) {
            this.currentMenuTab = tab
        },
        setactiveTheme: function (theme) {
            this.data_theme = theme
            document.body.className = 'theme-' + this.activeColor + ' ' + theme;
            EventBus.$emit('theme', theme)
        },
        backSideMenu: function () {
            document.body.classList.toggle('offcanvas-active')
        },
        isHover(module,hover){
            if(document.body.classList.contains('layout-fullwidth')){
                if(module == 'list'){
                    this.isListing = hover;
                }
                if(module == 'transaction'){
                    this.isTransactionlist = hover;
                }
                if(module == 'setting'){
                    this.isHoverSetting = hover;
                }
                if(module == 'customer'){
                    this.isCustomerlist = hover;
                }
            }
        },
    }, data() {
        return {
            isListing:false,
            isTransactionlist:false,
            isHoverSetting: false,
            isSidebarSmall:false,
            isCustomerlist:false,
            currentActiveMenu: this.$route.matched[0].path.replace(/\//, ''),
            //currentActiveMenu: window.location.pathname.split("/")[1],
            currentMenuTab: 'Menu',
            activeColor: 'turquoise',
            dropActive: '',
            data_theme: '',
            toggleSidebarClass: false,
            toggleSidebarTheme: '',
        }
    }, directives: {
        'click-outside': {
            bind: function (el, binding) {
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e)
                    }
                }
                el.__vueClickOutside__ = handler

                // add Event Listeners
                document.addEventListener('click', handler)
            },
        }
    }, computed: {
        currentRoutePath() {
            return this.$route.path;
        },

        activeMenu() {
            var menuList = this.$route.matched[0].path.split("/");
            var index = menuList.indexOf('admin');
            if (index > -1) {
                menuList.splice(index, 1);
            }
            var matchedMenu = menuList.filter(item => item);
            return matchedMenu[0]
        }
    }, watch: {
        $route() {
            this.currentActiveMenu = this.activeMenu.replace(/\//, '');
            document.body.className = 'theme-' + this.activeColor + ' ' + this.data_theme;
            if(sessionStorage.getItem("sidebarmini") == 'true'){
                document.body.classList.add("layout-fullwidth")
            }
        }
    }, mounted() {
        if(sessionStorage.getItem("sidebarmini") == 'true'){
            document.body.classList.add("layout-fullwidth")
            this.isSidebarSmall = true;
        }
        EventBus.$on('theme', (theme) => {
            this.toggleSidebarTheme = theme
        })
        EventBus.$on('sidebar-mini', (change) => {
            this.isSidebarSmall = change;
            this.currentMenuTab = 'Menu'
        })
        this.currentActiveMenu = this.activeMenu.replace(/\//, '');
    }
}
</script>

